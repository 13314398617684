import { PageType } from '@innedit/innedit-type';
import { navigate, PageProps } from 'gatsby';
import { PageData } from 'packages/innedit';
import React, { FC } from 'react';

import HOCGroup from '~/components/Group/HOC';
import Page from '~/components/List/Item/Page';
import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import ListBody from '~/containers/Espace/List/Body';
import params from '~/params/page.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const PagePagesUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  params: { espaceId, pageId, channelId },
  user,
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        displayView
        docId={pageId}
        model={new PageData({ channelId, espaceId, params })}
        params={{ channelId }}
        type="update"
      >
        <HOCGroup
          addOnClick={() =>
            navigate(
              `/espaces/${espaceId}/channels/${channelId}/pages/create?parent=${pageId}`,
            )
          }
          bodyProps={{
            className: 'p-0',
          }}
          display="content"
          title="Sous pages"
        >
          <ListBody<PageType, PageData>
            allowSorting
            itemList={Page}
            model={
              new PageData({
                channelId,
                espaceId,
                params,
                orderDirection: 'desc',
                orderField: 'isHomePage',
                parentId: pageId,
                wheres: {
                  parent: pageId,
                },
              })
            }
            search={location.search}
            title="Sous pages"
            user={user}
          />
        </HOCGroup>
      </Form>
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PagePagesUpdate);
